<template>
  <v-card mt-5>
    <v-card-text style="text-align: center">
      <v-btn color="primary" @click.prevent.stop="migrateDatabase">Migrate Database to Invoice</v-btn>
      <span>{{ message }}</span>
    </v-card-text>
  </v-card>
</template>

<script>
import InvoiceMigration from "../services/InvoiceMigration";
import moment from "moment";

export default {
  name: "InvoiceMigration",
  data() {
    return {
      invoices: [],
      message: ''
    }
  },
  mounted() {
    InvoiceMigration.all({})
        .then((response) => {
          const {resellers, sites} = response.data;
          resellers.forEach((item) => {
            const {
              refcode,
              email,
              refno,
              payerId,
              stripeId,
              paymentId,
              token,
              price: totalAmount,
              paymentStatus,
              userId,
              createdAt,
              updatedAt,
              link
            } = item;
            const links = link.split("@");
            const total = Number.parseFloat(totalAmount) / links.length;
            const carts = [];
            links.forEach((link) => {

              const webSite = link.split("/");
              const _webSite = sites.find((item) => item.site_url === webSite[2]);

              carts.push({
                siteId: _webSite && _webSite.id ? _webSite.id : 14,
                liveLink: link,
                price: total,
                quantity: 1,
                userId
              })
            })
            this.invoices.push({
              refcode,
              email,
              name: '',
              refno,
              payerId,
              stripeId,
              paymentId,
              token,
              totalAmount,
              paymentStatus,
              userId,
              createdAt: moment(createdAt).format('YYYY-MM-DD HH:mm:ss'),
              updatedAt: moment(updatedAt).format('YYYY-MM-DD HH:mm:ss'),
              paymentDate: paymentStatus ? moment(updatedAt).format('YYYY-MM-DD HH:mm:ss') : null,
              carts: carts
            })
          })
        })
  },
  methods: {
    migrateDatabase() {
      this.message = '';

      InvoiceMigration.store({invoices: this.invoices})
          .then((response) => {
            this.message = response.data.message;
          })
          .catch((errors) => {
            this.message = 'The validation error!';
          })
    }
  }
}
</script>

<style scoped>

</style>